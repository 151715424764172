import React, { useEffect } from "react";
import ReactGA from "react-ga";
import QuestionHeader from "../components/Question/QuestionHeader";
import QuestionContent from "../components/Question/QuestionContent";
import { questions } from "../data";

const Question = ({ activeQuestion, changeActiveQuestion, checkAnswer }) => {
  useEffect(() => {
    ReactGA.ga("set", "page", "/questions");
  }, []);

  return (
    <div className="question screen">
      <QuestionHeader step={activeQuestion} questions={questions} />
      <QuestionContent
        changeActiveQuestion={changeActiveQuestion}
        checkAnswer={checkAnswer}
        questions={questions}
        activeQuestion={activeQuestion}
      />
    </div>
  );
};

export default Question;

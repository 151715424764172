import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import App from "./App";
import "./scss/main.scss";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "okko-quiz.firebaseapp.com",
  databaseURL: "https://okko-quiz.firebaseio.com",
  projectId: "okko-quiz",
  storageBucket: "okko-quiz.appspot.com",
  messagingSenderId: "611292188457",
  appId: "1:611292188457:web:e01e71ea4c66689f503138",
};
firebase.initializeApp(firebaseConfig);

ReactGA.initialize("UA-22793027-20");
ReactGA.pageview(window.location.pathname);

const tagManagerArgs = {
  gtmId: "GTM-5DWBB7R",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

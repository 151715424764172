import React from 'react';

const Loader = () => {
  return (
    <div className='loader-wrapper'>
      <div className='loader'>
      <div className="loadingio-spinner-rolling-2k3y17qpul8"><div className="ldio-f9px2v6kwsu">
      <div></div>
      </div></div>
      </div>
    </div>
  )
}

export default Loader

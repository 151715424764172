import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import Paramount from "../components/Ui/Paramount";
import Policy from "../components/Ui/Policy";
import Share from "../components/Share/Share";
import close from "../img/close.svg";
import logo from "../img/logo.png";
import logoWhite from "../img/logo-white.png";
import comedy from "../img/comedy-logo.png";
import ruble from "../img/russia.svg";
import { resultsContent } from "../data";
var intersectionBy = require("lodash.intersectionby");

const Result = ({ results, item, deletePromocode }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [answers] = useState(localStorage.getItem("answers").split(","));
  const dataLayer = window.dataLayer || [];

  useEffect(() => {
    ReactGA.ga("set", "page", "/result");
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        setPopupShow(false);
      }
    });
  });

  let film;
  let compareOne = intersectionBy(answers, results[0].items);
  let compareTwo = intersectionBy(answers, results[1].items);
  let compareThree = intersectionBy(answers, results[2].items);
  const promocode = localStorage.getItem("promocode");

  if (
    answers.toString() === results[0].items.toString() ||
    answers.toString() === ["A", "C", "B", "D", "D"].toString() ||
    answers.toString() === ["A", "C", "D", "D", "D"].toString()
  ) {
    film = "south-park";
  } else if (
    answers.toString() === results[1].items.toString() ||
    answers.toString() === ["D", "D", "D", "A", "A"].toString() ||
    answers.toString() === ["C", "D", "C", "A", "A"].toString() ||
    answers.toString() === ["D", "D", "C", "A", "A"].toString()
  ) {
    film = "drawn-together";
  } else if (
    answers.toString() === results[2].items.toString() ||
    answers.toString() === ["B", "A", "A", "D", "B"].toString() ||
    answers.toString() === ["B", "B", "A", "B", "B"].toString() ||
    answers.toString() === ["B", "B", "A", "C", "C"].toString() ||
    answers.toString() === ["B", "A", "A", "C", "C"].toString() ||
    answers.toString() === ["B", "A", "A", "B", "B"].toString() ||
    answers.toString() === ["B", "A", "A", "C", "B"].toString() ||
    answers.toString() === ["B", "A", "A", "B", "C"].toString()
  ) {
    film = "ugly-americans";
  } else {
    if (
      compareOne.length > compareTwo.length &&
      compareOne.length > compareThree.length
    ) {
      film = "south-park";
    } else if (
      compareTwo.length > compareOne.length &&
      compareTwo.length > compareThree.length
    ) {
      film = "drawn-together";
    } else if (
      compareThree.length > compareOne.length &&
      compareThree.length > compareTwo.length
    ) {
      film = "ugly-americans";
    } else if (compareOne.length === compareTwo.length) {
      if (
        results[0].items[results[0].items.length - 1].charCodeAt(0) >
        answers[answers.length - 1].charCodeAt(0)
      ) {
        film = "drawn-together";
      } else {
        film = "south-park";
      }
    } else if (compareThree.length === compareTwo.length) {
      if (
        results[1].items[results[1].items.length - 1].charCodeAt(0) >
        answers[answers.length - 1].charCodeAt(0)
      ) {
        film = "ugly-americans";
      } else {
        film = "drawn-together";
      }
    } else if (compareThree.length === compareOne.length) {
      if (
        results[2].items[results[2].items.length - 1].charCodeAt(0) >
        answers[answers.length - 1].charCodeAt(0)
      ) {
        film = "south-park";
      } else {
        film = "ugly-americans";
      }
    }
  }

  return (
    <div className={`result ${film}`}>
      {film === "south-park" || film === "drawn-together" ? (
        <img src={logo} className="result__logo" alt="okko" />
      ) : (
        <img src={logoWhite} className="result__logo" alt="okko" />
      )}

      <div className={`result__header ${film}`}>
        <Paramount />
      </div>

      {film === "south-park" ? (
        <Policy classname={film}>{resultsContent[0].policy}</Policy>
      ) : film === "ugly-americans" ? (
        <Policy classname={film}>{resultsContent[1].policy}</Policy>
      ) : (
        <Policy classname={film}>{resultsContent[2].policy}</Policy>
      )}

      <h2>
        Твоя вселенная: &mdash;
        <br />
        {film === "south-park"
          ? resultsContent[0].filmName
          : film === "ugly-americans"
          ? resultsContent[1].filmName
          : resultsContent[2].filmName}
      </h2>

      <p className="result__text">
        {film === "south-park"
          ? resultsContent[0].text
          : film === "ugly-americans"
          ? resultsContent[1].text
          : resultsContent[2].text}
      </p>

      <p className="result__promo">
        {film === "south-park"
          ? resultsContent[0].filmName
          : film === "ugly-americans"
          ? resultsContent[1].filmName
          : resultsContent[2].filmName}{" "}
        и&nbsp;еще 50 000 фильмов
      </p>

      <div className="result-btn-wrapper">
        <p className="ressult__text-sm">
          Активируй промокод на&nbsp;30&nbsp;дней до&nbsp;30.08.20. Для новых
          пользователей
        </p>
        <span
          onClick={() => {
            if (item) {
              deletePromocode(item.id);
            }
          }}
        >
          <a
            className="btn"
            href={
              film === "south-park"
                ? `https://okko.tv/serial/south-park?utm_source=quiz&utm_medium=referral&utm_campaign=paramount_southpark&utm_content=result#promo-code/${promocode}`
                : film === "ugly-americans"
                ? `https://okko.tv/serial/ugly-americans?utm_source=quiz&utm_medium=referral&utm_campaign=paramount_uglyusa&utm_content=result#promo-code/${promocode}`
                : `https://okko.tv/serial/drawn-together?utm_source=quiz&utm_medium=referral&utm_campaign=paramount_multreality&utm_content=result#promo-code/${promocode}`
            }
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: "Quiz_Paramount",
                action: "Promo_active",
                label: `${window.location.hostname}${window.location.pathname}`,
              });
              dataLayer.push({
                event: "Quiz_Paramount",
                details: {
                  step: "Promo_active",
                },
              });
            }}
          >
            Смотри за 1 <img src={ruble} alt="P" />
          </a>
        </span>
      </div>
      <Share />
      <span className="result__more">
        Промокод доступен только для новых пользователей и&nbsp;дает доступ
        к&nbsp;подписке &laquo;Оптимум&raquo; за&nbsp;1&nbsp;рубль
        на&nbsp;30&nbsp;дней. Смотрите 50&nbsp;000&nbsp;фильмов, сериалов
        и&nbsp;мультфильмов без рекламы и&nbsp;в&nbsp;отличном качестве.
        Подробности акции{" "}
        <span className="share__popup_show" onClick={() => setPopupShow(true)}>
          здесь
        </span>
        .
      </span>
      <div className={`share__popup ${popupShow ? "active" : ""}`}>
        <img src={logo} className="result__logo" alt="okko" />
        <p>
          Сроки акции: 20.07.20-30.08.20. Акция действует для пользователей,
          ранее не&nbsp;совершавших покупок в&nbsp;Сервисе Okko
          и&nbsp;не&nbsp;участвовавших в&nbsp;любых других акциях Сервиса Okko.
          В&nbsp;рамках акции предоставляется промокод, дающий 30&nbsp;дней
          доступа к&nbsp;подписке &laquo;Оптимум&raquo; за&nbsp;1&nbsp;рубль.
          Один пользователь может активировать только один промокод
          в&nbsp;рамках акции. В&nbsp;день окончания 30-дневного периода
          за&nbsp;использование подписки &laquo;Оптимум&raquo; будет списана
          абонентская плата. <br />
          Сервис Okko&nbsp;18+. <br />
          <br />
          Как активировать промокод:{" "}
          <a
            href="http://code.okko.tv/"
            target="_blank"
            rel="noopener noreferrer"
          >
            code.okko.tv
          </a>
        </p>
        <img
          className="share__popup_close"
          src={close}
          onClick={() => setPopupShow(false)}
          alt="X"
        />
      </div>
      <img className="result-comedy-mobile" src={comedy} alt="comedy-central" />
    </div>
  );
};

export default Result;

import React from "react";
import RadioButton from "../Forms/RadioButton";
import Policy from "../Ui/Policy";

const QuestionContent = ({
  questions,
  activeQuestion,
  changeActiveQuestion,
  checkAnswer,
}) => {
  const index = activeQuestion - 1;
  return (
    <div className="question__content">
      <div className="question-quiz">
        {questions[index].answers.map((item) => {
          return (
            <div className="question-quiz__item" key={item.id}>
              <RadioButton
                id={item.id}
                label={item.description}
                name={`questions-${activeQuestion}`}
                activeQuestion={activeQuestion}
                changeHandler={() => {
                  changeActiveQuestion();
                  checkAnswer(item.version);
                }}
              />
            </div>
          );
        })}
      </div>
      {questions[index].policy ? (
        <Policy classname="policy--question">{questions[index].policy}</Policy>
      ) : null}
    </div>
  );
};

export default QuestionContent;

import React from 'react';
import {Link} from 'react-router-dom';

const Button = ({children, link, clickHandler}) => {
  return (
    <Link
      className='btn'
      onClick={clickHandler}
      to={link}
    >
      {children}
    </Link>
  )
}

export default Button

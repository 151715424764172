import React from 'react';
import Paramount from '../Ui/Paramount';
import Policy from '../Ui/Policy';

const QuestionHeader = ({step, questions}) => {
  const index = step - 1;
  return (
    <div className={`question__header question__header--${step}`}>
      <span className='question__step'>{step} из 5</span>
      <h2>{questions[index].title}</h2>
      <Paramount />
      {
        questions[index].policy ?
        <Policy classname='policy--question'>
          {questions[index].policy}
        </Policy> : null
      }
    </div>
  )
}

export default QuestionHeader

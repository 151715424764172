export const questions = [
  {
    id: 1,
    title: 'У твоего друга день рождения, он закатывает вечеринку в самом классном заведении города. Но... тебя не пригласили. Как отреагируешь?',
    policy: '«Южный парк» 18+. ©2020 VIACOMCBS. Все права защищены',
    answers: [
      {
        id: 1,
        version: 'A',
        description: 'Заявлюсь на вечеринку и испорчу ее. Это будет идеальная вечеринка – для меня'
      },
      {
        id: 2,
        version: 'B',
        description: 'Сначала расстроюсь, а потом встречусь с другом, и мы отпразднуем вдвоем – еще круче и веселее'
      },
      {
        id: 3,
        version: 'C',
        description: 'Все равно приду тусоваться'
      },
      {
        id: 4,
        version: 'D',
        description: 'Обижусь и буду наблюдать за всем в инстаграме'
      }
    ]
  },
  {
    id: 2,
    title: 'Твой босс – сущий дьявол. Постоянно ругается и перекладывает на тебя всю работу. Что ты будешь делать?',
    policy: '«Гадкие американцы» 18+. ©2020 VIACOMCBS. Все права защищены',
    answers: [
      {
        id: 1,
        version: 'A',
        description: 'Работать еще лучше'
      },
      {
        id: 2,
        version: 'B',
        description: 'Попрошу повышения'
      },
      {
        id: 3,
        version: 'C',
        description: 'Придумаю хитрый план, как занять его место'
      },
      {
        id: 4,
        version: 'D',
        description: 'Уволюсь со скандалом. Я знаю себе цену'
      }
    ]
  },
  {
    id: 3,
    title: 'В твой город массово приезжают чудовища. Они занимают рабочие места, заводят семьи и баллотируются в президенты. Как отнесешься к этому?',
    policy: '«Гадкие американцы» 18+. ©2020 VIACOMCBS. Все права защищены',
    answers: [
      {
        id: 1,
        version: 'A',
        description: 'Помогу им адаптироваться – вместе веселее'
      },
      {
        id: 2,
        version: 'B',
        description: 'В панике сбегу в другой город'
      },
      {
        id: 3,
        version: 'C',
        description: 'Буду напряженно игнорировать понаехавших монстров'
      },
      {
        id: 4,
        version: 'D',
        description: 'Устрою протест! Мало им своих миров, так еще к нам приезжают'
      }
    ]
  },
  {
    id: 4,
    title: 'Быть взрослым – круто. Хотя бы потому, что можно есть что хочется и когда угодно. Чем будешь питаться?',
    policy: '«Мультреалити» 18+. ©2020 VIACOMCBS. Все права защищены',
    answers: [
      {
        id: 1,
        version: 'A',
        description: 'Только курочкой от Полковника'
      },
      {
        id: 2,
        version: 'B',
        description: 'Обычную еду и много овощей'
      },
      {
        id: 3,
        version: 'C',
        description: 'Хлопья с молоком на завтрак, обед и ужин'
      },
      {
        id: 4,
        version: 'D',
        description: 'Я просто люблю есть'
      }
    ]
  },
  {
    id: 5,
    title: 'Какие фильмы ты любишь?',
    policy: '«Южный парк» 18+. ©2020 VIACOMCBS. Все права защищены',
    answers: [
      {
        id: 1,
        version: 'A',
        description: 'Смотрю кино ради развлечения, можно без смысла'
      },
      {
        id: 2,
        version: 'B',
        description: 'Нравится легкое доброе кино о приключениях'
      },
      {
        id: 3,
        version: 'C',
        description: 'Если герой попадает в ситуацию, из которой выносит урок, мне понравится'
      },
      {
        id: 4,
        version: 'D',
        description: 'Сатира на злобу дня и скрытый смысл, который поймут не все'
      }
    ]
  }
];

export const results = [
  {
    items: [
      'A', 'C', 'B', 'D', 'D'
    ]
  },
  {
    items: [
      'C', 'D', 'C', 'A', 'A'
    ]
  },
  {
    items: [
      'B', 'A', 'A', 'B', 'B'
    ]
  }
];

export const resultsContent = [
  {
    id: 1,
    filmName: '«Южный парк»',
    policy: '«Южный парк» 18+. ©2020 VIACOMCBS. Все права защищены',
    text: 'Ты точно знаешь почему киса плохая и кто такой Мистер Шляпа. Никому не даешь 3.50, и после фразы "Они убили Кенни!"- непроизвольно кричишь в ответ: "Сволочи!" ? Ты точно - житель Саус Парка...шикарно...'
  },
  {
    id: 2,
    filmName: '«Гадкие американцы»',
    policy: '«Гадкие американцы» 18+. ©2020 VIACOMCBS. Все права защищены',
    text: 'Ты легко спасешь город от клонов Ларри Кинга, выдержишь взрывной характер своей девушки-демона и даже поужинаешь с Сатаной в аду. Сразу видно — типичный житель Нью-Йорка.'
  },
  {
    id: 3,
    filmName: '«Мультреалити»',
    policy: '«Мультреалити» 18+. ©2020 VIACOMCBS. Все права защищены',
    text: 'Если ты окажешься запертым в доме, окруженным видеокамерами, фантазия и чувство юмора не дадут тебе заскучать. Тут и модная лицензия детектива не нужна — ты точно житель «Мультреалити».'
  }
];

import React, { Component, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import Home from "./pages/Home";
import Question from "./pages/Question";
import Result from "./pages/Result";
import Loader from "./components/Ui/Loader";
import { results } from "./data";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      activeQuestion: 1,
      promocodes: [],
      isLoaded: false,
      promocode: localStorage.getItem("promocode"),
      item: null,
    };
    this.checkAnswer = this.checkAnswer.bind(this);
    this.changeActiveQuestion = this.changeActiveQuestion.bind(this);
    this.deletePromocode = this.deletePromocode.bind(this);
  }

  componentDidMount() {
    if (!this.state.promocode) {
      firebase
        .firestore()
        .collection("data")
        .doc("duoiCvJ13SwaYzrjLaq3")
        .get()
        .then((doc) => {
          if (doc.exists) {
            const randomIndex = Math.floor(
              Math.random() * doc.data().data.length
            );
            const item = doc.data().data[randomIndex];
            this.setState({
              promocodes: doc.data().data,
              isLoaded: true,
              item: item,
            });
          }
        });
    } else {
      this.setState({
        isLoaded: true,
      });
    }
  }

  checkAnswer(value) {
    const { answers } = this.state;
    this.setState({
      answers: [...answers, value],
    });
    localStorage.setItem("answers", [...answers, value]);
  }

  async deletePromocode(id) {
    const data = this.state.promocodes;
    const idx = data.findIndex((item) => item.id === id);
    await firebase
      .firestore()
      .collection("data")
      .doc("duoiCvJ13SwaYzrjLaq3")
      .set({
        data: [...data.slice(0, idx), ...data.slice(idx + 1)],
      });
  }

  changeActiveQuestion() {
    let { activeQuestion } = this.state;
    if (activeQuestion < 5) {
      setTimeout(() => {
        this.setState({
          activeQuestion: activeQuestion + 1,
        });
        window.scrollTo(0, 0);
      }, 1000);
    } else if (activeQuestion === 5) {
      this.props.history.push("/result");
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { activeQuestion, item, isLoaded, promocode } = this.state;

    let routes = (
      <Fragment>
        <Route exact path="/">
          <Home
            deletePromocode={this.deletePromocode}
            item={item}
            promocode={promocode}
          />
        </Route>
        <Route exact path="/questions">
          <Question
            changeActiveQuestion={this.changeActiveQuestion}
            checkAnswer={this.checkAnswer}
            activeQuestion={activeQuestion}
          />
        </Route>
        <Route exact path="/result">
          <Result
            results={results}
            deletePromocode={this.deletePromocode}
            item={item}
          />
        </Route>
      </Fragment>
    );

    return (
      <div className="app">
        {!isLoaded ? <Loader /> : null}
        {routes}
      </div>
    );
  }
}

export default withRouter(App);

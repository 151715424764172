import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Button from "../components/Forms/Button";
import Paramount from "../components/Ui/Paramount";
import Policy from "../components/Ui/Policy";
import pic from "../img/home-pic.png";

const Home = ({ item, promocode }) => {
  const dataLayer = window.dataLayer || [];
  useEffect(() => {
    ReactGA.ga("set", "page", "/");
  }, []);

  return (
    <div className="main-screen screen">
      <img src={pic} className="main-screen__mob-pic" alt="" />
      <div className="main-screen__mob-bg" />
      <Paramount />
      <div className="main-screen__content">
        <h1>Из какой вы мультвселенной?</h1>
        <p>
          Какой из&nbsp;персонажей культовых мультсериалов нулевых вам ближе?{" "}
          <br />
          Участник реалити-шоу, заблудший монстр или школьник из&nbsp;Колорадо?
        </p>
        <p className="subtitle">
          Пройдите тест и&nbsp;получите подарок от&nbsp;Okko.
        </p>
        <Button
          link="/questions"
          clickHandler={() => {
            ReactGA.event({
              category: "Quiz_Paramount",
              action: "Start",
              label: `${window.location.hostname}${window.location.pathname}`,
            });
            dataLayer.push({
              event: "Quiz_Paramount",
              details: {
                step: "Start",
              },
            });
            ReactGA.ga("send", "pageview", "/questions");
            if (!promocode) {
              window.scrollTo(0, 0);
              localStorage.setItem("promocode", item.value);
            } else {
              window.scrollTo(0, 0);
            }
          }}
        >
          Пройти тест
        </Button>
        <Policy classname="policy--main">
          &quot;Гадкие американцы&quot;,&quot;Южный парк&laquo;,
          &laquo;Мультреалити&raquo; 18+. &copy;2020&nbsp;VIACOMCBS. Все права
          защищены
        </Policy>
      </div>
    </div>
  );
};

export default Home;

import React, { Fragment } from 'react';
import logo from '../../img/paramount.png';

const Paramount = () => {
  return (
    <Fragment>
      <img className='paramount-logo' src={logo} alt="Paramount"/>
    </Fragment>
  )
}

export default Paramount

import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";

class RadioButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
    this.checkedTimeout = this.checkedTimeout.bind(this);
    this.setChecked = this.setChecked.bind(this);
  }

  setChecked() {
    this.setState({
      checked: true,
    });
  }

  checkedTimeout() {
    this.timerHandle = setTimeout(() => {
      this.setState({
        checked: false,
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerHandle);
  }

  render() {
    const { name, id, label, changeHandler, activeQuestion } = this.props;
    const { checked } = this.state;
    const dataLayer = window.dataLayer || [];
    return (
      <Fragment>
        <input
          type="radio"
          name={name}
          id={id}
          checked={checked}
          onClick={() => {
            this.checkedTimeout();
            ReactGA.event({
              category: "Quiz_Paramount",
              action: `Step${activeQuestion}`,
              label: `${window.location.hostname}${window.location.pathname}`,
            });
            dataLayer.push({
              event: "Quiz_Paramount",
              details: {
                step: `Step${activeQuestion}`,
              },
            });
            if (activeQuestion === 5) {
              ReactGA.ga("send", "pageview", "/result");
            }
          }}
          onChange={() => {
            changeHandler();
            this.setChecked();
          }}
        />
        <label htmlFor={id}>{label}</label>
      </Fragment>
    );
  }
}

export default RadioButton;

import React from 'react';
import ReactGA from 'react-ga';
import tg from '../../img/telegram.svg';
import vk from '../../img/vk.svg';
import fb from '../../img/facebook.svg';
import arrow from '../../img/share-arr.png';

const Share = () => {
  const dataLayer = window.dataLayer || [];
  return (
    <div className='share'>
      <span className='share__text'>Зови друзей в свою вселенную</span>
      <a
        target='_blank'
        rel="noopener noreferrer"
        href="https://vk.com/share.php?&url=https://paramount.okko.tv/"
        onClick={() => {
          ReactGA.event({
            category: 'Quiz_Paramount',
            action: 'Share_vk',
            label: `${window.location.hostname}${window.location.pathname}`
          });
          dataLayer.push({
            event: 'Quiz_Paramount',
            details: {
              step: 'Share',
              option: 'vk'
            }
          });
        }}
      >
        <img src={vk} alt='vk-share' />
      </a>
      <a
        target='_blank'
        rel="noopener noreferrer"
        href="tg://msg?text=Смотри культовые шоу Comedy Central в Okko 30 дней за 1₽ на okko.tv: https://paramount.okko.tv/"
        onClick={() => {
          ReactGA.event({
            category: 'Quiz_Paramount',
            action: 'Share_tg',
            label: `${window.location.hostname}${window.location.pathname}`
          });
          dataLayer.push({
            event: 'Quiz_Paramount',
            details: {
              step: 'Share',
              option: 'tg'
            }
          });
        }}
      >
        <img src={tg} alt='tg-share' />
      </a>
      <a
        target='_blank'
        rel="noopener noreferrer"
        href="https://www.facebook.com/sharer/sharer.php?u=https://paramount.okko.tv/"
        onClick={() => {
          ReactGA.event({
            category: 'Quiz_Paramount',
            action: 'Share_fb',
            label: `${window.location.hostname}${window.location.pathname}`
          });
          dataLayer.push({
            event: 'Quiz_Paramount',
            details: {
              step: 'Share',
              option: 'fb'
            }
          });
        }}
      >
        <img src={fb} alt='facebook-share' />
      </a>
      <img className='share__arrow' src={arrow} alt='arrow' />
    </div>
  )
}

export default Share

import React from 'react';

const Policy = ({children, classname}) => {
  return (
    <span className={`policy ${classname}`}>
      {children}
    </span>
  )
}

export default Policy
